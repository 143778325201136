import * as yup from 'yup';
import { Form, Row } from 'antd';
import React, { memo, useCallback, useState } from 'react';
import styled from '@emotion/styled';

import { FormElementChangeEvent, Password } from './../../../components/forms';

import { Alert, Button } from '../../../components';
import validateWithSchema from '../../../utils/yup/validateWithSchema';
import { DefaultHeading } from '../../../layout/user-layout';
import { IResetPasswordFormData } from '../../../types/models/auth';
import { MESSAGE_STATUS, PASSWORD_CONFIG } from '../../../constants';

type ResetPasswordProps = {
  onResetPassword: (formData: IResetPasswordFormData) => void;
  status: string;
};

const StyledRow = styled(Row)`
  width: 75%;
  padding-left: 300px;
  max-width: 900px;
`;

const ResetPasswordForm = memo(function ResetPasswordForm({ onResetPassword, status }: ResetPasswordProps) {
  const [formData, setFormData] = useState<{
    password?: string;
    confirmPassword?: string;
  }>({});

  const [formErrors, setFormErrors] = useState<Record<string, any>>({});

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .min(PASSWORD_CONFIG.MIN_LENGTH as number, PASSWORD_CONFIG.MIN_LENGTH_ERROR as string)
      .matches(PASSWORD_CONFIG.RULE as RegExp, PASSWORD_CONFIG.RULE_ERROR as string)
      .required(PASSWORD_CONFIG.REQUIRED_ERROR as string),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password')], 'Passwords must match')
      .required('Confirm Password is required'),
  });

  const handleSubmit = useCallback(
    async () => {
      const { errors } = await validateWithSchema(validationSchema, false, formData);

      setFormErrors(errors);

      if (errors && Object.keys(errors).length === 0) {
        const resetPasswordPayload: IResetPasswordFormData = {
          password: formData.password as string,
        };

        onResetPassword(resetPasswordPayload);
      }
    },
    [formData, validationSchema, onResetPassword]
  );

  const handleOnChange = useCallback(
    ({ name, value }: FormElementChangeEvent) => {
      if (name) setFormData(prevState => ({ ...prevState, [name]: value }));
    },
    [setFormData]
  );

  const renderAlert = () => {
    if (status === MESSAGE_STATUS.ERROR) {
      return <Alert message="Password reset email cannot be sent" type="error" />;
    } else {
      return null;
    }
  };
  return (
    <div>
      <StyledRow>
        <DefaultHeading> Reset Password </DefaultHeading>
        {renderAlert()}
        <Form name="basic" onFinish={handleSubmit} layout={'vertical'}>
          <Form.Item
            label="Password"
            validateStatus={formErrors['password'] ? 'error' : ''}
            help={formErrors['password'] && formErrors['password'].message}
          >
            <Password name="password" placeholder="Password" onChange={handleOnChange} />
          </Form.Item>

          <Form.Item
            label="Confirm New Password"
            validateStatus={formErrors['confirmPassword'] ? 'error' : ''}
            help={formErrors['confirmPassword'] && formErrors['confirmPassword'].message}
          >
            <Password name="confirmPassword" placeholder="Confirm Password" onChange={handleOnChange} />
          </Form.Item>
          <Form.Item>
            <Button type="primary-blue" htmlType="submit" block>
              Reset Password
            </Button>
          </Form.Item>
        </Form>
      </StyledRow>
    </div>
  );
});

export default ResetPasswordForm;
